import "@cospex/client/abtest";
import { AuthProvider } from "@cospex/client/context/AuthProvider";
import CustomTranslationProvider from "@cospex/client/context/CustomTranslationProvider";
import CustomizationProvider from "@cospex/client/context/CustomizationContext";
import { CssBaseline, Theme } from "@mui/material";
import { SxProps, ThemeProvider } from "@mui/material/styles";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useNavigationType,
} from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 0,
      cacheTime: 0,
    },
  },
});

if (import.meta.env.PROD) {
  let dsn = "";
  if (import.meta.env.VITE_SENTRY_WEB_DSN !== "") {
    dsn = import.meta.env.VITE_SENTRY_WEB_DSN as string;
  }

  Sentry.init({
    dsn: dsn,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

export default function Providers({
  theme,
  children,
  customization,
}: {
  theme: Theme;
  children: React.ReactNode;
  customization?: {
    logo: {
      src: string;
      sx?: SxProps;
      textOnly?: boolean;
    };
    companyInfoImage: string;
    languages: LanguageSelectOptions;
  };
}) {
  return (
    <React.StrictMode>
      <CustomizationProvider value={customization}>
        <CustomTranslationProvider>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <ThemeProvider theme={theme}>
                <ParallaxProvider>
                  <CssBaseline />
                  {children}
                </ParallaxProvider>
              </ThemeProvider>
            </AuthProvider>
          </QueryClientProvider>
        </CustomTranslationProvider>
      </CustomizationProvider>
    </React.StrictMode>
  );
}
