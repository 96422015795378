import { useEffect, useState } from "react";

const usePaymentLibraries: any = () => {
  const [deviceSession, setDeviceSession] = useState<any>(null);
  const [riskScriptReady, setRiskScriptReady] = useState(false);
  const [secureTradingReady, setSecureTradingReady] = useState(false);
  const isProduction =
    import.meta.env.PROD && import.meta.env.MODE !== "staging";
  const key = import.meta.env.VITE_CHECKOUT_PUBLIC_KEY;

  const loadSecureTradingScript = () => {
    const script = window.document.createElement("script");
    script.src = "https://webservices.securetrading.net/js/v3/st.js";
    script.async = true;
    script.onload = () => {
      setSecureTradingReady(true);
    };
    window.document.body.appendChild(script);
  };

  const loadRiskScript = () => {
    if (window.document.getElementById("risk-js") || !isProduction) {
      return;
    }

    const script = window.document.createElement("script");
    script.src = "https://risk.checkout.com/cdn/risk/1/risk.js";
    script.async = true;
    script.id = "risk-js";

    script.onload = () => {
      setRiskScriptReady(true);
    };

    window.document.body.appendChild(script);
  };

  useEffect(() => {
    loadRiskScript();
    loadSecureTradingScript();
  }, []);

  useEffect(() => {
    if (riskScriptReady && isProduction) {
      const { Risk } = window as any;
      const risk = Risk.init(key);

      risk.publishRiskData().then((result: any) => {
        if (result.error) {
          console.error(result.error);
        } else {
          setDeviceSession(result);
        }
      });
    }
  }, [riskScriptReady]);

  return {
    deviceSession,
    riskScriptReady,
    secureTradingReady,
  };
};

export default usePaymentLibraries;
