import IphoneImg from "@cospex/client/assets/tracker/message_preview_phone.svg";
import { getToken } from "@cospex/client/context/AuthProvider";
import TextInput from "@cospex/client/forms/TextInput";
import { getItemWithExpiry, setItemWithExpiry } from "@cospex/client/helpers";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowForward, Info } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import intlTelInput, { Plugin } from "intl-tel-input";
import "intl-tel-input/build/js/utils";
import { useEffect, useRef, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { z } from "zod";

let iti1: Plugin;
let iti2: Plugin;

const MessageBox = ({ html }: any) => (
  <Box
    dangerouslySetInnerHTML={{
      __html: html,
    }}
    sx={{
      p: 2,
      borderRadius: "10px",
      backgroundColor: "#43CC47",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      width: "100%",
    }}
  ></Box>
);

function usePhoneLocator() {
  const inputRef1 = useRef<any>(null);
  const inputRef2 = useRef<any>(null);

  const result = useQuery({
    queryKey: ["ipapi"],
    enabled: false,
    queryFn: async () => {
      return await axios.get<{ country_code: string }>("https://ipapi.co/json");
    },
  });

  useEffect(() => {
    iti1 = intlTelInput(inputRef1.current, {
      initialCountry: "auto",
      geoIpLookup: function (callback) {
        result
          .refetch()
          .then((result) => {
            if (result.data) {
              callback(result.data.data.country_code);
              return;
            }
            callback("fr");
          })
          .catch(() => {
            callback("fr");
          });
      },
      nationalMode: true,
      formatOnDisplay: true,
      preferredCountries: ["FR", "DE", "GB", "ES", "IT", "BE"],
    });
    iti2 = intlTelInput(inputRef2.current, {
      initialCountry: "auto",
      geoIpLookup: function (callback) {
        result
          .refetch()
          .then((result) => {
            if (result.data) {
              callback(result.data.data.country_code);
              return;
            }
            callback("fr");
          })
          .catch(() => {
            callback("fr");
          });
      },
      nationalMode: true,
      formatOnDisplay: true,
      preferredCountries: ["FR", "DE", "GB", "ES", "IT", "BE"],
    });
  }, []);
  return [inputRef1, inputRef2];
}

type NameForm = {
  phoneGeoloc: string;
  phone?: string;
  templateId: number;
  firstname?: string;
  lastname?: string;
};

type LanguageCode = "fr" | "en" | "it";

type SMSTemplate = {
  id: number;
  title: string;
  enabled: boolean;
  translations: Record<LanguageCode, string>;
};

const getTemplateHtml = (
  lang: LanguageCode,
  template?: SMSTemplate,
  firstname?: string,
  lastname?: string
) => {
  if (!template) {
    return "Loading...";
  }
  if (Object.keys(template.translations).length === 0) {
    return "No templates found.";
  }
  const html = template.translations[lang];
  if (!firstname && !lastname) {
    return html
      .replace("(#firstname# #lastname#)", "")
      .replace(/<br>/g, "")
      .trim();
  }
  const name = `${firstname || ""} ${lastname || ""}`.trim();
  return html.replace("(#firstname# #lastname#)", `(${name})`);
};

export default function DashboardPhoneLocator() {
  const { t, i18n } = useTranslation();
  const [ref1, ref2] = usePhoneLocator();
  const result = useQuery({
    queryKey: ["sms-template"],
    queryFn: async () => {
      return await axios.get<SMSTemplate[]>("/api/tracker/get-template-sms", {
        withCredentials: true,
        headers: { Authorization: "Bearer " + getToken() },
      });
    },
  });

  const [success, setSuccess] = useState(false);

  const newTrackingMutation = useMutation({
    networkMode: "always",
    mutationFn: ({
      phoneGeoloc,
      phone,
      templateId,
      firstname,
      lastname,
    }: NameForm) => {
      const intlphoneGeoloc = intlTelInputUtils.formatNumber(
        phoneGeoloc,
        iti1.getSelectedCountryData().iso2,
        intlTelInputUtils.numberFormat.INTERNATIONAL
      );
      const intlphone = intlTelInputUtils.formatNumber(
        phone,
        iti2.getSelectedCountryData().iso2,
        intlTelInputUtils.numberFormat.INTERNATIONAL
      );
      const body = {
        phoneGeoloc: intlphoneGeoloc,
        phone: intlphone,
        templateId: templateId,
        firstname: firstname,
        lastname: lastname,
        language: i18n?.language,
      };
      if (phone) {
        const intlPhone = intlTelInputUtils.formatNumber(
          phone,
          iti2.getSelectedCountryData().iso2,
          intlTelInputUtils.numberFormat.INTERNATIONAL
        );
        body.phone = intlPhone;
      }
      return axios.post("/api/tracker/request-tracking", body, {
        withCredentials: true,
        headers: { Authorization: "Bearer " + getToken() },
      });
    },
    onSuccess: () => {
      setSuccess(true);
      setItemWithExpiry("onboardingNumber", "");
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    onError: (data: any) => {
      const apiError = data.response?.data?.error;
    },
  });
  const { control, handleSubmit, formState, watch } = useForm<NameForm>({
    mode: "onTouched",
    defaultValues: {
      phoneGeoloc: getItemWithExpiry("onboardingNumber"),
      phone: "",
      firstname: "",
      lastname: "",
      templateId: 1,
    },
    resolver: zodResolver(
      z.object({
        phoneGeoloc: z
          .string()
          .min(1, t("phone-form-tracking-invalid") as string)
          .refine(
            () => {
              return iti1.isValidNumber();
            },
            {
              message: t("phone-form-tracking-invalid") as string,
            }
          ),
        phone: z
          .string()
          .refine(
            (phone) => {
              // If phone is not provided, skip validation
              if (!phone) {
                return true;
              }
              // If phone is provided, validate it
              return iti2.isValidNumber();
            },
            {
              message: t("phone-form-tracking-invalid") as string,
            }
          )
          .optional(),
        firstname: z.string().optional(),
        lastname: z.string().optional(),
        templateId: z.number(),
      })
    ),
  });

  const onSubmit: SubmitHandler<NameForm> = (data) =>
    newTrackingMutation.mutate(data);

  const userName = watch("firstname");
  const userLastName = watch("lastname");
  const templateId = watch("templateId");

  const currTemplate = result.data?.data.find(
    (template: SMSTemplate) => template.id === templateId
  );

  const currTemplateHtml = getTemplateHtml(
    i18n.language,
    currTemplate,
    userName,
    userLastName
  );

  return (
    <Stack spacing={4}>
      <Typography variant="h3" color="primary.main" mb={2}>
        {t("dashboard-sms-title")}
      </Typography>
      {success ? (
        <>
          <Typography>{t("dashboard-history-sms-success")}</Typography>
          <Button
            disableElevation
            to="/dashboard/history"
            component={Link}
            variant="contained"
            endIcon={<Info sx={{ color: "white" }} />}
          >
            <Typography color="white">{t("dashboard-history")}</Typography>
          </Button>
        </>
      ) : (
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Grid container sx={{ mb: 4 }}>
            <Grid item xs={12} md={6}>
              <Box mb={2}>
                <Typography variant="h6" gutterBottom>
                  {t("dashboard-sms-confirm-phone")}
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>
                <TextInput
                  formState={formState}
                  inputRef={ref1}
                  control={control}
                  name="phoneGeoloc"
                  fullWidth
                />
              </Box>
              <Box mb={2}>
                <Typography variant="h6" gutterBottom>
                  {t("dashboard-sms-enter-phone")}
                </Typography>
                <TextInput
                  formState={formState}
                  inputRef={ref2}
                  control={control}
                  name="phone"
                  label={t("phone-form-label-optional")}
                  sx={{
                    "& input::placeholder": {
                      fontSize: "0.715rem",
                    },
                  }}
                  fullWidth
                />
              </Box>
              <Stack spacing={1} sx={{ mb: 4 }}>
                <Typography variant="h6" gutterBottom>
                  {t("dashboard-sms-fill-name")}
                </Typography>
                <TextInput
                  formState={formState}
                  control={control}
                  name="firstname"
                  label={t("dashboard-sms-firstname")}
                  fullWidth
                />
                <TextInput
                  formState={formState}
                  control={control}
                  name="lastname"
                  label={t("dashboard-sms-lastname")}
                  fullWidth
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} sx={{ p: 4 }}>
              <Box
                sx={{
                  backgroundImage: `url(${IphoneImg})`,
                  backgroundSize: "cover",
                  height: "100%",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    pt: "18%",
                    px: "7%",
                  }}
                >
                  <MessageBox html={currTemplateHtml} />
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    height: "55px",
                    background:
                      "linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Box mb={2}>
            <Typography variant="h6" gutterBottom>
              {t("dashboard-sms-message-template")}
            </Typography>
          </Box>
          <Stack>
            <Controller
              name="templateId"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  value={field.value.toString()} // Convert current value to string
                  onChange={(e) => field.onChange(parseInt(e.target.value))} // Parse new value to number
                >
                  {result.data?.data.map((template: SMSTemplate) => {
                    const labelHTML = getTemplateHtml(
                      i18n.language,
                      template,
                      userName,
                      userLastName
                    );
                    return (
                      <FormControlLabel
                        sx={{
                          m: 0,
                          mb: 2,
                        }}
                        key={template.id}
                        value={template.id.toString()} // Convert template id to string
                        control={<></>}
                        label={
                          <Card
                            sx={{
                              border:
                                field.value === template.id
                                  ? "2px solid"
                                  : "2px solid transparent",
                              borderColor:
                                field.value === template.id
                                  ? "primary.main"
                                  : "transparent",
                            }}
                            onClick={() => field.onChange(template.id)} // Set selected template on click
                          >
                            <CardContent>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: labelHTML,
                                }}
                              />
                            </CardContent>
                          </Card>
                        }
                      />
                    );
                  })}
                </RadioGroup>
              )}
            />
          </Stack>
          <Stack
            alignItems="center"
            direction="column"
            sx={{
              padding: "0 4px",
              gap: "1rem",
            }}
          >
            <LoadingButton
              sx={{ mt: 2, py: 1.3, color: "white" }}
              disableElevation
              type="submit"
              variant="contained"
              color="primary"
              endIcon={<ArrowForward />}
              loading={newTrackingMutation.isLoading}
            >
              <Typography textTransform="uppercase">
                {t("phone-form-button")}
              </Typography>
            </LoadingButton>
            <Button
              disableElevation
              to="/dashboard/tutorial"
              component={Link}
              variant="outlined"
              endIcon={<Info />}
              disabled={newTrackingMutation.isLoading}
            >
              {t("dashboard-sms-tutorial-link")}
            </Button>
          </Stack>
        </Box>
      )}
    </Stack>
  );
}
