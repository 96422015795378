import { getToken } from "@cospex/client/context/AuthProvider";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { FloatingCard } from "@cospex/client/tracker/components/Layout";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Skeleton, Typography } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

export default function Account() {
  const { t } = useTranslation();
  const { isLoading, error, data } = useQuery(["accountData"], () =>
    axios
      .get<Account>("/api/dashboard-account", {
        withCredentials: true,
        headers: { Authorization: "Bearer " + getToken() },
      })
      .then((res) => res.data)
  );

  const resetPassMutation = useMutation({
    mutationFn: async () => {
      return await axios.post("/api/request-password", null, {
        headers: { Authorization: "Bearer " + getToken() },
      });
    },
  });

  const handleResetPassword = () => {
    resetPassMutation.mutate();
  };

  if (isLoading)
    return (
      <>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={200}
          sx={{ mb: 4 }}
        />
        <Skeleton
          variant="rectangular"
          width="100%"
          height={200}
          sx={{ mb: 4 }}
        />
        <Skeleton
          variant="rectangular"
          width="100%"
          height={200}
          sx={{ mb: 4 }}
        />
      </>
    );

  if (error || !data)
    return (
      <Alert severity="error">
        There was a problem with your request. Please try again later. If the
        problem persists, contact support.
      </Alert>
    );

  return (
    <>
      <FloatingCard sx={{ mb: 3 }}>
        <Typography variant="h3" color="primary.main" mb={2}>
          {t("dashboard-my-account")}
        </Typography>
        <Typography variant="body1" mb={3}>
          <strong>{t("dashboard-account-email-title")}</strong>
          <br />
          {data.email}
        </Typography>
        <Typography variant="body1">
          <strong>{t("dashboard-account-reset-title")}</strong>
          <br />
          <Box mb={1}>{t("dashboard-account-reset-description")}</Box>
          <LoadingButton
            variant="contained"
            disableElevation
            onClick={handleResetPassword}
            disabled={resetPassMutation.isLoading}
            sx={{
              color: "white",
              backgroundColor: resetPassMutation.isSuccess
                ? "success.main"
                : "primary",
            }}
          >
            <span>
              {resetPassMutation.isSuccess
                ? t("dashboard-account-reset-success")
                : t("dashboard-account-reset-button")}
            </span>
          </LoadingButton>
        </Typography>
      </FloatingCard>
    </>
  );
}
