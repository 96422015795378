import { Box } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useEffect } from "react";

interface Position {
  coords: {
    latitude: number;
    longitude: number;
  };
}

export default function RequestLocation({ id }: { id: string }) {
  const mutation = useMutation((position: Position) => {
    const { latitude, longitude } = position.coords;
    const exec = async () => {
      await axios.post(
        `/api/geoloc/position/${window.atob(id)}/${latitude}/${longitude}`
      );
      window.location.href =
        "http://maps.google.com/?q=" + latitude + "," + longitude;
    };
    return exec();
  });

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        mutation.mutate({
          coords: {
            latitude: latitude,
            longitude: longitude,
          },
        });
      });
    } else {
      console.warn("Not on a device that supports the Geolocation API");
    }
  }, []);

  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        mb: 4,
      }}
    >
      Requesting location
      <CircularProgress />
    </Box>
  );
}
