import useTranslation from "@cospex/client/hooks/useTranslation";

export const getPrices = (lang: string) => {
  const prices = __PRICES__;

  const formatPrice = (price: number) => {
    const formattedPrice = price.toFixed(2).toString().replace(".", ",");
    switch (lang) {
      case "en":
        return `${prices.symbol}${price}`;
      default:
        return `${formattedPrice} ${prices.symbol}`;
    }
  };

  const localeSubscriptionPrice = formatPrice(prices.subscriptionPrice);
  const localeRebillPrice = formatPrice(prices.rebillPrice);

  const interpolate = (text: string) => {
    return text
      .replace(/SUBSCRIPTION_PRICE/g, localeSubscriptionPrice)
      .replace(/REBILL_PRICE/g, localeRebillPrice);
  };

  return {
    ...prices,
    localeSubscriptionPrice,
    localeRebillPrice,
    interpolate,
  };
};

export default function usePrices() {
  const { i18n } = useTranslation();
  return getPrices(i18n.language);
}
