import Header from "@cospex/client/components/Header";
import Footer from "@cospex/client/tracker/components/Footer";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

export default function App() {
  return (
    <Box>
      <Header
        menu={{
          dashboardHref: "/dashboard/sms",
        }}
      />
      <Outlet />
      <Footer />
    </Box>
  );
}
