import { getRandomValueScaledToDaytime } from "@cospex/client/utils/daytimeScaler";
import { Circle } from "@mui/icons-material";
import { SxProps, Theme, Typography } from "@mui/material";
import { useEffect, useState } from "react";

interface ICounter {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}

export default function Counter({ children, sx }: ICounter) {
  const dateKey = new Date().toLocaleDateString("en-GB");
  const countKey = dateKey + "_counter";

  const cachedCount = () => {
    const cache = localStorage.getItem(countKey);
    return cache ? parseInt(cache) : null;
  };

  const [count, setCount] = useState(
    cachedCount() || getRandomValueScaledToDaytime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (Math.random() < 0.5) {
        setCount((prevCount) => prevCount + 1);
      }
    }, 10_000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem(countKey, count.toString());
  }, [count]);

  return (
    <Typography
      sx={{
        bgcolor: "#EFEDED",
        p: "10px",
        mt: "1rem",
        maxWidth: "max-content",
        borderRadius: "10px",
        ...sx,
      }}
    >
      <Circle
        sx={{
          fontSize: "0.6rem",
          verticalAlign: "center",
          mr: "0.5rem",
          color: "primary.main",
        }}
      />
      {children} <b>{count}</b>
    </Typography>
  );
}
