import usePrices from "@cospex/client/hooks/usePrices";
import useTranslation from "@cospex/client/hooks/useTranslation";
import CircleArrow from "@cospex/client/tracker/img/circlearrow.svg";
import { TaskAlt } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";

import { ShadowCard } from "./Layout";
import { List, StyledUL } from "./List";

const ListTask = ({ label }: { label: string }) => {
  return (
    <List
      startAdornment={<TaskAlt sx={{ color: "primary.main" }} />}
      label={label}
    />
  );
};

const Pricing = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const handleButtonClick = () => {
    if (location.pathname === "/pricing") {
      navigate("/");
      return;
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  const prices = usePrices();
  return (
    <ShadowCard sx={{ backgroundColor: "white" }}>
      <Stack sx={{ alignItems: "center", paddingBottom: "3rem" }}>
        <Typography variant="h2" fontWeight={600}>
          {t("pricing-heading")}
        </Typography>
      </Stack>
      <Stack
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
          justifyContent: "center",
          rowGap: "2rem",
          width: "100%",
        }}
      >
        <Stack
          sx={{
            flex: "1 1 0",
            alignItems: { xs: "left", sm: "center" },
            paddingLeft: "3vw",
          }}
        >
          <Typography variant="h4" textAlign="center">
            {t("pricing-title")}
            <Box component="span" sx={{ color: "primary.main" }}>
              {t("pricing-title-addendum")}
            </Box>
          </Typography>
          <StyledUL sx={{ textAlign: "left" }}>
            <ListTask label={t("pricing-list-one")} />
            <ListTask label={t("pricing-list-two")} />
            <ListTask label={t("pricing-list-three")} />
            <ListTask label={t("pricing-list-four")} />
            <ListTask label={t("pricing-list-five")} />
            <ListTask label={t("pricing-list-six")} />
            <ListTask label={t("pricing-list-seven")} />
            <ListTask label={t("pricing-list-eight")} />
          </StyledUL>
        </Stack>

        {/* Right Side */}
        <Stack
          sx={{
            flex: "1 1 0",
            alignItems: "center",
            rowGap: "1rem",
          }}
        >
          <Stack
            sx={{
              rowGap: "1rem",
              position: "relative",
              width: "100%",
              alignItems: "center",
            }}
          >
            <svg
              style={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                fontSize: "3.5rem",
              }}
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="20"
                cy="20"
                r="19.5"
                fill="white"
                stroke={theme.palette.primary.main}
              />
              <path
                d="M30.8333 20.5L29.0121 18.6788L21.7917 25.8863V10.1667H19.2083V25.8863L12.0008 18.6658L10.1667 20.5L20.5 30.8333L30.8333 20.5Z"
                fill={theme.palette.primary.main}
              />
            </svg>
            <Stack
              sx={{
                flexDirection: "row",
                border: "1px solid",
                borderColor: "primary.main",
                borderRadius: "10px",
                padding: "1rem",
                width: "100%",
                maxWidth: "346px",
                height: "89px",
                alignItems: "center",
              }}
            >
              <Stack
                sx={{
                  flex: "1 1 0",
                  color: "primary.main",
                  textAlign: "center",
                }}
              >
                <Typography variant="h5">{t("pricing-48-hours")}</Typography>
                <Typography variant="h5">
                  {t("pricing-48-trial-offer")}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  flex: "1 1 0",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <Typography variant="h5">{t("pricing-48")}</Typography>
              </Stack>
            </Stack>
            <Stack
              sx={{
                flexDirection: "row",
                border: "1px solid",
                borderColor: "primary.main",
                borderRadius: "10px",
                padding: "1rem",
                width: "100%",
                maxWidth: "346px",
                height: "89px",
                alignItems: "center",
              }}
            >
              <Stack
                sx={{
                  flex: "1 1 0",
                  color: "primary.main",
                  textAlign: "center",
                }}
              >
                <Typography variant="h5">
                  {t("pricing-48-subscription")}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  flex: "1 1 0",
                  textAlign: "center",
                }}
              >
                <Typography variant="h5">{t("pricing-48-rebill")} </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              maxWidth: "346px",
              rowGap: "1rem",
            }}
          >
            <Typography>{t("pricing-48-desc")}</Typography>
            <Button variant="contained" onClick={handleButtonClick}>
              <Typography color="white">{t("pricing-48-start")}</Typography>
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </ShadowCard>
  );
};

export default Pricing;
