import useTranslation from "@cospex/client/hooks/useTranslation";
import Device from "@cospex/client/tracker/components/icons/Device";
import Network from "@cospex/client/tracker/components/icons/Network";
import Phone from "@cospex/client/tracker/components/icons/Phone";
import { Box, Stack, SxProps, Theme, Typography } from "@mui/material";
import { TypographyVariant } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { ReactElement } from "react";

export function AllTypeCard({
  Icon,
  title,
  desc,
  variant,
}: {
  Icon: React.FC<{ color: string }>;
  title: string | ReactElement;
  desc: string | ReactElement;
  variant?: TypographyVariant;
  sx?: SxProps<Theme>;
}) {
  const theme = useTheme();
  return (
    <Stack
      sx={{ flex: "1 1 0", alignItems: "center", textAlign: "center" }}
      gap={2}
    >
      <Box
        sx={{
          height: "5rem",
          width: "7rem",
        }}
      >
        <Icon color={theme.palette.primary.main} />
      </Box>
      <Typography variant={variant}>{title}</Typography>
      <Typography>{desc}</Typography>
    </Stack>
  );
}

export default function AllType() {
  const { t } = useTranslation();
  return (
    <Stack
      sx={{
        flexDirection: { xs: "column", sm: "row" },
        justifyContent: "center",
        gap: 4,
      }}
    >
      <AllTypeCard
        Icon={Phone}
        title={t("home-all-phone-title")}
        desc={t("home-all-phone-desc")}
        variant="h3"
      />
      <AllTypeCard
        Icon={Network}
        title={t("home-all-network-title")}
        desc={t("home-all-network-desc")}
        variant="h3"
      />
      <AllTypeCard
        Icon={Device}
        title={t("home-all-device-title")}
        desc={t("home-all-device-desc")}
        variant="h3"
      />
    </Stack>
  );
}
