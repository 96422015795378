import { useCustomization } from "@cospex/client/hooks/useCustomization";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { Section } from "@cospex/client/tracker/components/Layout";
import AllType from "@cospex/client/tracker/components/sections/AllType";
import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";

export default function PhonesLocated() {
  const { t } = useTranslation();
  const { logo } = useCustomization();
  return (
    <Section>
      <Stack
        sx={{
          alignItems: "center",
          rowGap: "1rem",
          mb: 4,
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${logo.src})`,
            backgroundRepeat: "no-repeat",
            width: 40,
            height: 40,
            paddingRight: "3rem",
          }}
        />
        <Typography color="primary.main" variant="h4">
          {t("home-phones-located-title")}
        </Typography>
        <Typography>{t("home-phones-located-desc")}</Typography>
      </Stack>
      <AllType />
    </Section>
  );
}
