import useTranslation from "@cospex/client/hooks/useTranslation";
import { Box } from "@mui/material";

type IContactFormNumber = {
  contactNumbers?: { [key: string]: string };
};

const ContactFormNumber = ({ contactNumbers = {} }: IContactFormNumber) => {
  const { i18n } = useTranslation();
  const number = contactNumbers[i18n.language];
  if (!number) return null;

  return (
    <Box
      sx={{
        mb: 2,
        pb: 2,
        borderBottom: "2px solid #f2f2f2",
        alignItems: "center",
        fontWeight: "bold",
        fontSize: 22,
      }}
    >
      📞 {number}
    </Box>
  );
};

export default ContactFormNumber;
