import Contact from "@cospex/client/tracker/components/Contact";

export default function ContactPage() {
  return (
    <Contact
      contactNumbers={{
        fr: "01 87 66 79 45",
      }}
    />
  );
}
