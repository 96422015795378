import { withTransientProps } from "@cospex/client/helpers";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import {
  Card,
  IconButton,
  SxProps,
  Theme,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";

type WrapperProps = {
  children: React.ReactNode;
};
interface FloatingCardProps extends WrapperProps {
  elevation?: number;
  className?: string;
  sx?: SxProps<Theme>;
}

const FloatingCardElement = styled(Card)<{
  elevation: number;
}>`
  padding: 1.5rem;
  box-shadow: 0px ${({ elevation }) => elevation * 1.5}px
    ${({ elevation }) => `
      ${elevation * 5}px
    `}
    rgba(0, 0, 0, ${({ elevation }) => (elevation > 5 ? 0.07 : 0.1)});
`;
export function FloatingCard({
  children,
  elevation = 2,
  className,
  sx,
}: FloatingCardProps) {
  return (
    <FloatingCardElement className={className} sx={sx} elevation={elevation}>
      {children}
    </FloatingCardElement>
  );
}

const CarouselContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const CarouselItemsContainer = styled("div")`
  overflow: visible;
  flex-grow: 1;
  width: 100%;
`;

const CarouselItems = styled("div")`
  display: flex;
  transition: transform 0.3s ease-in-out;
  width: 100%;
  align-items: stretch;
`;

const CarouselItemInner = styled(FloatingCard)`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

const CarouselItemContainer = styled("div")`
  padding: 1rem 0.5rem;
  min-width: 0;
  flex: 0 0 calc(100% / 3);
  @media (max-width: 959px) {
    flex-basis: calc(100% / 2);
  }
  @media (max-width: 599px) {
    flex-basis: 100%;
    max-width: 100%;
  }
`;

const CarouselArrow = styled(IconButton, withTransientProps)<{
  $left?: boolean;
  $right?: boolean;
}>`
  ${({ theme, $left, $right }) => `
    ${$left ? "left: -50px;" : ""}
    ${$right ? "right: -50px;" : ""}
    position: absolute;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    padding: 8px;
    margin: 0 8px;
    z-index: 2;

    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
    ${theme.breakpoints.down("lg")} {
      ${$left ? "left: 5px;" : ""}
      ${$right ? "right: 5px;" : ""}
    }
  `}
`;

export const CarouselItem = ({ children }: { children: React.ReactNode }) => (
  <CarouselItemInner elevation={10}>{children}</CarouselItemInner>
);

const Carousel = ({ children }: { children: React.ReactNode[] }) => {
  const [index, setIndex] = useState(0);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const itemsPerPage = isDesktop ? 3 : isTablet ? 2 : 1;

  const prevItem = () => {
    setIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  const nextItem = () => {
    setIndex((prevIndex) =>
      prevIndex < children.length - itemsPerPage ? prevIndex + 1 : prevIndex
    );
  };

  return (
    <CarouselContainer>
      <CarouselArrow onClick={prevItem} $left>
        <ArrowBackIos sx={{ transform: "translateX(5px)" }} />
      </CarouselArrow>
      <CarouselItemsContainer>
        <CarouselItems
          style={{ transform: `translateX(-${(index * 100) / itemsPerPage}%)` }}
        >
          {React.Children.map(children, (child) => (
            <CarouselItemContainer>{child}</CarouselItemContainer>
          ))}
        </CarouselItems>
      </CarouselItemsContainer>
      <CarouselArrow onClick={nextItem} $right>
        <ArrowForwardIos />
      </CarouselArrow>
    </CarouselContainer>
  );
};

export default Carousel;
